
<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- <common-app-page-header
        :title="'Create Warrant Term'"
      /> -->
      <v-card-title class="mt-3 pt-6 pl-8">
        <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Create Warrant Term</span>
      </v-card-title>
      <common-trs-card class="pa-2 pb-0 mb-2">
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
          class="ml-auto"
        >
          <v-card-text class="pa-0">
            <form>
              <warrant-term-form
                v-model="warrantTermData"
                :financing-rounds="financingRoundList"
                :share-terms="shareTermList"
                :warrant-term-id="warrantTermId"
                :is-submit="isSubmit"
                @update="update"
                @navToList="navToList"
              />
            </form>
          </v-card-text>
          <v-card-text class="pa-0">
            <v-row class="mx-3">
              <v-col>
                <v-divider />
                <div class="d-flex justify-space-between align-center pt-3">
                  <common-trs-btn
                    type="secondary"
                    medium
                    class="pull-right mr-2"
                    text
                    :to="{name: 'ShareTerms', query: { tab: 1 }}"
                  >
                    Cancel
                  </common-trs-btn>
                  <common-trs-btn
                    type="primary"
                    medium
                    class="white--text pull-right"
                    text
                    :disabled="!valid"
                    :loading="loading"
                    @click="save"
                  >
                    Save
                  </common-trs-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </validation-observer>
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>
<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import WarrantTermForm from '@/components/common/captable/components/forms/WarrantTermForm'

  export default {
    name: 'CreateWarrantTerm',

    components: {
      WarrantTermForm,
    },

    data: () => ({
      loading: false,
      financingRoundList: [],
      shareTermList: [],
      warrantTermData: {},
      warrantTermId: '',
      isSubmit: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    created () {
      this.loadFinancingRounds()
      this.loadShareTerms()
    },

    methods: {
      async loadFinancingRounds () {
        try {
          const resp = await captableService.getAllFinancingRounds(this.captableId)
          if (resp && resp.data) {
            this.financingRoundList = resp.data.financing_rounds
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data.',
          })
        }
      },
      async loadShareTerms () {
        try {
          const resp = await captableService.getShareTermList(this.captableId)
          if (resp && resp.data) {
            this.shareTermList = resp.data.share_terms
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data.',
          })
        }
      },
      async save () {
        this.loading = true
        try {
          const resp = await captableService.draftWarrantTerm(this.captableId, this.warrantTermData)
          if (resp.status === 201) {
            this.warrantTermId = resp.data.id
            this.isSubmit = true
          }
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to save Warrant term. Please try again later.',
          })
        }
      },
      update (val) {
        this.warrantTermData = val
      },
      navToList () {
        this.loading = false
        this.$store.dispatch('app/message', { text: 'Warrant term saved successfully.' })
        this.$router.push({ name: 'ShareTerms', query: { tab: 1 } })
      },
      close () {
        this.$router.push({ name: 'ShareTerms', query: { tab: 1 } })
      },
    },
  }
</script>
